import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconBuildFuture from 'stile-shared/assets/icons/icon_build_future.svg';
import iconEngFirst from 'stile-shared/assets/icons/icon_eng_first.svg';
import iconExtendSkills from 'stile-shared/assets/icons/icon_extend_skills.svg';
import iconGrowCareer from 'stile-shared/assets/icons/icon_grow_career.svg';
import iconLoveWorking from 'stile-shared/assets/icons/icon_love_working.svg';
import iconOutsizedInfluence from 'stile-shared/assets/icons/icon_outsized_influence.svg';
import iconSolveProblems from 'stile-shared/assets/icons/icon_solve_problems.svg';
import iconsTeamBased from 'stile-shared/assets/icons/icon_team_based.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { JobsListBlock } from 'stile-shared/src/components/2020/blocks/JobsListBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function EngineeringAtStilePage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        media={
          <MediaBlock as="div" layout="montage-five">
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/comp_engineering_01.png"
              alt="A headshot of a Black, female engineer drawing a diagram on a transparent whiteboard, viewed through the board itself"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/comp_engineering_02.png"
              alt="An engineer stands in front of a whiteboard, writing onto a notepad in his hand"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/comp_engineering_03.png"
              alt="An engineer is sitting with a laptop on his lap, looking thoughtful at something off camera"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/comp_engineering_04.jpg"
              alt="A group of people watching each other play a game outside, alternately laughing and gasping in surprise"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/comp_engineering_05.png"
              alt="A closeup of someone's hand resting on a blue computer mouse"
            />
          </MediaBlock>
        }
      />

      <TextBlock>
        <P>
          Software engineers at Stile work as a close-knit team, often in collaboration with the
          teachers, artists, videographers and subject matter experts who bring Stile to life. From
          crafting simulations that allow students to explore complex scientific topics, to scaling
          and building upon our classroom teaching platform that’s used by over 200,000 students
          every week, we do it all. We solve problems quickly while maintaining our relentless
          commitment to building high-quality tools that last. We’re a small team who have outsized
          influence. The tools that we create genuinely improve science education for thousands of
          students around the globe.
        </P>
        <P>
          If you’re keen to have a rewarding, impactful career that will help improve scientific
          literacy amongst young people globally, we’d love to hear from you.
        </P>
      </TextBlock>

      <TeaserBlock
        variant="dark"
        title="Meet the engineering team"
        text="We asked our engineers about their work at Stile. Here’s what they had to say."
        video={{
          text: 'Meet the eng team',
          url: 'https://youtu.be/XjhAW68PN3I',
          coverImage: (
            <StaticImage
              src="../../../../stile-shared/assets/images/engineering-at-stile/engineering_video_cover.jpg"
              alt="A Black, female engineer is standing in front of a diagram written on a clear board. The board is lit so the marker glows"
            />
          ),
        }}
      />

      <IconCardsBlock
        title="How we work"
        max="1200px"
        cards={[
          {
            icon: { src: iconEngFirst },
            title: 'An engineering-first approach',
            text: 'We know that engineering drives the success of our product. We have an engineering-first approach from the top down. From Stile’s founder and majority owner — Alan Finkel — to our CEO and CTO, Stile is owned and led by engineers.',
          },
          {
            icon: { src: iconOutsizedInfluence },
            title: 'Have outsized influence',
            text: 'Stile is still only a small team, yet we’re already used by 1 in 3 secondary school students in Australia and are rapidly growing internationally. To allow us to move quickly but safely, we invest in CI and DevOps to release multiple times per day. Understanding that tomorrow’s work is just as important as today’s, we fix open bugs before starting new work, and make progress on internal tooling each sprint. Write code, ship it, see it used by 70,000 students within 24 hours, and iterate.',
          },
          {
            icon: { src: iconBuildFuture },
            title: 'Build for the long-term',
            text: 'Stile is a profitable, founder-led startup with a strong long-term product vision. We build exciting tools that last for years (not months) on a maintainable platform of great technology. We use a modern web tech stack featuring React, TypeScript, Ruby, and Rust with strongly-typed API boundaries (defined with Protocol Buffers), and highly-available, auto-scaling containerized deployment.',
          },
          {
            icon: { src: iconSolveProblems },
            title: 'Solve difficult, interesting, important problems',
            text: 'From designing an analytics interface so intuitive that a busy teacher can understand it at a glance, to maintaining transactional consistency in a globally-distributed high-throughput database and even designing simulations, you’ll tackle interesting engineering problems that test your existing skills and teach you new ones.',
          },
          {
            icon: { src: iconExtendSkills },
            title: 'Extend your skillset',
            text: 'We’ll help you to gain the skills you need to thrive. Work on front-end, back-end and operations projects, and even cross-departmentally with our user experience and customer-facing teams. If you’re looking to specialise, join our product or platform guild to delve more deeply and drive technological development.',
          },
          {
            icon: { src: iconsTeamBased },
            title: 'Team-based approach with the flexibility you need',
            text: 'Teamwork is core to our success. Pair-program, solve problems together, grab a coffee to discuss ideas. Get a subject matter expert on the phone — even if it’s the CTO. It’s your teammates’ first priority to be available to help. That said, we also know the importance of meeting-free time and flexibility. Complete your inspired engineering work from wherever best suits you: home, a cafe or the office.',
          },
          {
            icon: { src: iconLoveWorking },
            title: 'People you love working with',
            text: 'We’re committed to promoting a workplace where every team member is excited about coming to work. We do everything in our power to make this an amazing place to work; we hire and manage very deliberately to provide it. You will work with outstanding people who value teamwork and collaboration.',
          },
          {
            icon: { src: iconGrowCareer },
            title: 'Grow your career at Stile',
            text: 'You’ll be given the resources and guidance you need to excel. We’ll provide you with transparency around expectations, promotion and pay, and opportunities to lead. You’ll attend conferences as well as internal and external professional development events. Your manager will be an experienced engineer who will help you to grow and succeed, engaging in weekly one-on-one meetings with you to provide long-term coaching guidance that empowers your professional development.',
          },
        ]}
      />

      <JobsListBlock category="Engineering" />

      <NextPageBlock path="/who-we-are/join-the-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default EngineeringAtStilePage;
